import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import has from 'lodash/has'
import get from 'lodash/get'
import map from 'lodash/map'
import find from 'lodash/find'
import contentAPI from '../../contentAPI'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES, SECTIONS } from '../../utils/url'

export default class Breadcrumb extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
        }
        this.buildItemsPath()
    }

    async buildItemsPath() {
        const windowGlobal = typeof window !== 'undefined' && window
        const param = windowGlobal.localStorage?.getItem('param')
        const { page } = this.props
        const isPTP = page.__typename.includes('Ptp')
        const items = []
        let group = await this.getGroupFor(page)
        if (this.props.params && group) {
            group = group.filter((key, value) => key.slug === this.props.params)
            group = group[0]
        } else if (param && !this.props.params && group) {
            const groupTemp = group.filter((key, value) => key.slug === param)
            groupTemp.length === 0 && group ? (group = group[0]) : (group = groupTemp[0])
        } else {
            group = group ? group[0] : group
        }

        while (group) {
            items.unshift({
                title: group.title,
                url: buildURL(group, isPTP ? CONTENTFUL_GRAPHQL_TYPES.PAGES_GROUP_PTP : CONTENTFUL_GRAPHQL_TYPES.PAGES_GROUP),
            })
            group = await this.getGroupFor(group)
        }

        items.push({
            title: page.title,
            url: buildURL(page),
        })
        this.setState({ items })
    }

    getGroupFor(item) {
        if (has(item, 'groups[0].slug')) {
            return Promise.resolve(item.groups)
        }

        if (!item.contentful_id) {
            return Promise.resolve()
        }

        return contentAPI.query(`entries?sys.id=${item.contentful_id}`).then((results) => {
            if (!results.total) {
                return
            }

            const groupId = get(results, 'items[0].fields.groups[0].sys.id')
            if (!groupId) {
                return
            }

            const entries = get(results, 'includes.Entry')
            if (!entries || !entries.length) {
                return
            }

            const groupEntry = entries.find((entry) => get(entry, 'sys.id') === groupId)
            if (!groupEntry) {
                return
            }

            const group = {
                ...groupEntry.fields,
                contentful_id: groupEntry.sys.id,
            }
            return group
        })
    }

    render() {
        const { items } = this.state
        const userSection = find(SECTIONS, { key: this.props.page.userSection }) || SECTIONS.VISITOR
        const splitName = (name) => {
            return name
                ?.replace(/<br>/g, '')
                .split(' ')
                .map((part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        {index !== name.replace(/<br>/g, '').split(' ').length - 1 && ' '}
                    </React.Fragment>
                ))
        }
        return (
            <div className="breadcrumb-wrapper">
                <ul className="breadcrumb-list main">
                    <li>
                        <Link to={userSection.url}> {splitName(userSection.name)}</Link>
                    </li>
                    {map(items, (item) => (
                        <li key={item.url}>
                            <i className="fal fa-chevron-right" /> <Link to={item.url}>{splitName(item.title)}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}
