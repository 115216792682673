import React, { Fragment, PureComponent } from 'react';
import PageLayout from './PageLayout';
import Helmet from 'react-helmet';
import Breadcrumb from '../common/Breadcrumb';
import Share from '../share/Share';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import reactDom from 'react-dom';

export default class CustomLayout extends PureComponent {
    firstLoad=true;
    allowedLinks = ['text/css', 'font/woff2', 'font/woff'];

    constructor(props) {
        super(props);
        this.state = {
            htmlContent: null,
            iframeHeight: '0px',
        };
        this.onElementHeightChange = this.onElementHeightChange.bind(this);
        this.checkIfIframeLoaded = this.checkIfIframeLoaded.bind(this);
        this.iframeRef = React.createRef();
    }

    checkIfIframeLoaded() {
        const iframe = document.getElementById('responsive-iframe');
        const iframeDoc = iframe.contentDocument ||
            iframe.contentWindow.document;

        if (iframeDoc.readyState === 'complete' &&
            iframeDoc.getElementsByTagName('body').length) {
            // this.onElementHeightChange(iframe, this.updateIframeHeight,this.firstLoad);
            this.firstLoad = false;
            return;
        }
        window.setTimeout(this.checkIfIframeLoaded, 500);
    }

    onElementHeightChange(el, callback,firstLoad) {
        let lastHeight = el.contentWindow.document.getElementsByTagName('body')[0].scrollHeight;
        let newHeight;
        (function run() {
            let bodies = el.contentWindow.document.getElementsByTagName('body')
            if (bodies.length) {
                newHeight = bodies[0].scrollHeight;
                if (lastHeight !== newHeight || firstLoad) {
                    callback(newHeight);
                }
            }
            lastHeight = newHeight;
            el.onElementHeightChangeTimer = setTimeout(run, 750);
        })();
    }

    updateIframeHeight = (newHeight) => {
        this.setState({ iframeHeight: newHeight });
    }

    async componentDidMount() {
        const domParser = new DOMParser();
        const { html, css, scripts } = this.props.page;
        const htmlUrl = html.file?.url || html.fields.file.url;
        const val = await fetch('https:' + htmlUrl);
        const cssTags = css ? css.map(cssFile =>{
            const cssUrl = cssFile.file?.url ||  cssFile.fields.file?.url;
           return  `<link type="text/css" rel="stylesheet" href="https:${cssUrl}" />`}
        ).join('\n') : '';

        const scriptTags = scripts ? scripts.map(jsFile =>{
            const jsUrl = jsFile.file?.url || jsFile.fields.file.url
            return `<script type="text/javascript" src="https:${jsUrl}"></script>`}
        ).join('\n') : '';

        let dom = domParser.parseFromString(await val.text(), "text/html");
        if (!dom.getElementsByTagName('head') || dom.getElementsByTagName('head').length < 1) {
            dom = dom.createElement('head');
        }
        const completedhead = dom.getElementsByTagName('head')[0].innerHTML + cssTags + scriptTags;
       
        dom.getElementsByTagName('head')[0].innerHTML = completedhead;
        
        const body = dom.getElementsByTagName('html')[0].innerHTML;
       
        this.setState({ htmlContent: '<DOCTYPE html><html>' + body + '</html>' });
    }

    getBlobURL(code, type) {
        const blob = new Blob([code], { type })
        return URL.createObjectURL(blob)
    }

    render() {
       
        const { site, page, sitemap } = this.props;
        const activeSection = page ? page.userSection : '';
       
        const metaDescription = page ? page.metaDescription : null;
       
        return this.state.htmlContent ?
            (<PageLayout activeSection={activeSection} sitemap={sitemap}>
                {
                    page && (
                        <Fragment>
                            <Helmet>
                                <title>{site.title} - {page.title}</title>
                                <meta property="og:url" content={`${site.siteUrl}/${page.slug}`} />
                                <meta property="og:type" content="article" />
                                <meta property="og:title" content={`${site.title} - ${page.title}`} />
                                {(!!metaDescription) &&
                                    <meta name="description" content={metaDescription} />
                                }
                            </Helmet>
                            <Breadcrumb page={page} />
                            <main className={`wrapper`}>
                                <iframe
                                    id="responsive-iframe"
                                    ref={(ref) => this.iframeRef = ref}
                                    onLoad={(e) => setTimeout(() => {
                                        const iframe = reactDom.findDOMNode(this.iframeRef);
                                        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
                                    }, 500)}
                                    width="100%" 
                                    scrolling="no" 
                                    frameBorder="0"
                                    src={this.getBlobURL(this.state.htmlContent, 'text/html')}
                                />
                                {page.showSocialShare && (
                                    <div className={`share-section`}>
                                        <div className="wrapper" >
                                            <Share
                                                socialConfig={{
                                                    twitterHandle: site.twitterHandle,
                                                    config: {
                                                        url: `${site.siteUrl}/${page.slug}`,
                                                        title: `${site.title} - ${page.title}`
                                                    },
                                                }}
                                                tags={['Starship']}
                                            />
                                        </div>
                                    </div>
                                )}
                            </main>

                        </Fragment>
                    )
                }
            </PageLayout>) : (<PageLayout activeSection={activeSection} />)
    }
}
