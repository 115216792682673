import React, {Component} from 'react'
import { withPreview } from '../../components/common'
import CustomLayout from '../../components/layouts/CustomPageLayout'

class CustomPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: null,
            hasGroup: false,
            sitemap: []
        }
    }

    componentDidMount() {
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('customPage', this.props.location.search).then(entry => {
            this.initializeGenericPage(entry)
        })
    }


    initializeGenericPage(entry) {
        const page = {
            __typename: 'ContentfulPageCustom',
            ...entry.fields,
        }

        this.setState({
            page
        })
    }

    render() {
        const { page, sitemap } = this.state
        const site = this.props.getSiteInfo()

        return !this.props.loading && page && site ? <CustomLayout preview={true} site={site} page={page} sitemap={sitemap} /> : <></>
    }
}

export default withPreview(CustomPagePreview)