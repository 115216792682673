/**
 * This file provides an easy way to query data from Contentful's Content Delivery API
 * https://www.contentful.com/developers/docs/references/content-delivery-api/
 */
import get from 'lodash/get'
import axios from 'axios';

const contentAPI = {}

const BASE_URL = "https://cdn.contentful.com";
const API_URL = `${BASE_URL}/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`

const fetchGet = (url) => {
    return axios.get(url, {
        headers: { 'Authorization' : `Bearer ${process.env.GATSBY_CONTENTFUL_DELIVERY_TOKEN}` }
    })
    .then(response => response.data)
    .then(response => {
        if(get(response, 'details.errors.length')) {
            const errors = response.message;
            console.error(errors);
        }
        return response;
    })
}

/**
 * This method fetches data from the API sending a provided query.
 * @param {String} query - Needs to be a query as specified in Contentful's documentation (referenced at the beginning of this file)
 *   e.g.: "entries?sys.id=${contentful_id}", "entries?links_to_entry={contentful_id}&sys.contentType.sys.id[in]=genericPage,widgetsPage"
 * @returns {Promise} returns the API's response.
 */
contentAPI.query = (query) => {
    const url = `${API_URL}/${query}`
    return fetchGet(url);
}

export default contentAPI